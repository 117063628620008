body {
    background-color: #eeeeee;
    background-repeat: repeat;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
